@import '../../../../scss/theme-bootstrap';
$thumbnails-shown-on-desktop: 4;

.product-full__image {
  @include breakpoint($large-up) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  &-carousel {
    order: 1;
    @include breakpoint($large-up) {
      width: calc(75%);
    }
  }
  &-thumbnails {
    order: 0;
    // with 4 thumbs need to equal the height of the main image
    // 30px is the margin between 4 thumbs
    width: calc((75% / #{$thumbnails-shown-on-desktop}) - (30px / #{$thumbnails-shown-on-desktop}));
  }
  &--single {
    @include aspect-ratio(100, 100, 'img');
    width: 100%;
  }
}

.product-full__image-img {
  display: block;
  margin: 0 auto;
  @include breakpoint($large-up) {
    height: auto;
  }
  .zoom--active & {
    @include breakpoint($large-up) {
      @include opacity(0);
    }
  }
  .slick-active.zoom--active & {
    @include breakpoint($large-up) {
      @include opacity(0);
    }
  }
  &--alt {
    max-width: 100%;
    @include breakpoint($large-up) {
    }
    @include breakpoint($large-up) {
      padding-bottom: 0;
    }
  }
}

.product-full__image-single,
.product-full__carousel__slides {
  @include breakpoint($large-up) {
    text-align: center;
    overflow: hidden;
  }
}

/*
 * Load carousels without js. We use flex so that we can mark slides as
 * .initial-current-slide and have them show visible without js.
 */
.product-full__carousel__slides {
  overflow: hidden;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  &.slick-initialized {
    display: block;
  }

  // Pre carousel we move the initial slide to the front.
  .product-full__carousel__slide.initial-current-slide {
    display: block;
    order: 0;
  }

  // Once slick is initialized we show all slides.
  &.slick-initialzied .product-full__carousel__slide {
    display: block;
  }
}

.product-full__carousel {
  &__slide {
    @include aspect-ratio(100, 100, 'img');
    width: 100%;
    display: none; // prevent FOUC
    pointer-events: none; // prevent clicking peaked slides i.e. video
    @include breakpoint($large-up) {
      pointer-events: auto;
    }
    &.slick-current {
      pointer-events: auto;
    }
    .product-full__video {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .zoomImg {
      cursor: zoom-in;
    }
  }

  // Do not lazyload carousel images that aren't current.
  // We trigger off a preloadAssets class so we can trigger a full load later
  // on.
  &__slides:not(.preloadAssets) img.lazyload {
    display: none;
  }
  &__slides &__slide.slick-current {
    img.lazyload {
      display: block;
    }
  }

  /*
   * Run dynamically rendering. We set a class on the initial slide make sure
   * it lazyloads quickly.
   */
  &__slides &__slide.initial-current-slide {
    img.lazyload {
      display: block;
    }
  }
  // Note that on mobile we have center mode carousel. So we need to load the
  // left/right slide images.
  &__slides &__slide.active-right,
  &__slides &__slide.active-left {
    @include breakpoint($med-small-down) {
      img.lazyload {
        display: block;
      }
    }
  }
}

.product-full__image-thumbnails {
  display: none;
  position: relative;
  @include breakpoint($large-up) {
    display: block;
  }
  .slick-arrow {
    left: 50%;
    right: auto;
    margin-top: 0;
    color: #c5a37c;
    width: 30px;
    text-align: left;
    position: absolute;
    height: 50px;
    width: 30px;
    background: url(#{$base-theme-path}/img/icons/svg/arrow--right.svg) no-repeat;
    background-size: cover;
    z-index: 1;
  }
  .slick-next {
    @include transform(translate(-50%, 50%) rotate(90deg));
    top: auto;
    bottom: 0;
  }
  .slick-prev {
    @include transform(translate(-50%, -50%) rotate(-90deg));
    top: -10px;
  }
}

.product-full__carousel__thumbnail {
  @include aspect-ratio(100, 100, 'img');
  width: 100%;
  display: block;
  cursor: pointer;
  margin: 0 0 10px;
  overflow: hidden;
  .slick-vertical &.slick-slide {
    border: 0;
  }
  @include breakpoint($large-up) {
    &:nth-child(n + #{$thumbnails-shown-on-desktop + 1}) {
      display: none;
    }
    .slick-list & {
      display: block;
    }
  }
  img {
    top: 50%;
    transform: translateY(-50%);
  }
  &.active,
  &.slick-current {
    cursor: default;
  }
  img,
  .spp-olapic-element-bg {
    @include opacity(0.5);
  }
  &:hover,
  &.active,
  &.slick-current {
    img,
    .spp-olapic-element-bg {
      @include opacity(1);
    }
  }
}

.product-full__image-carousel {
  position: relative;
  .carousel-dots {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 4px;
    left: 0;
    .slick-dots {
      margin-top: 0;
    }
    @include breakpoint($large-up) {
      bottom: 15px;
      padding-left: 80px;
    }
  }
  .carousel-controls {
    display: block;
  }
  .slick-arrow {
    @include breakpoint($large-up) {
      opacity: 0;
    }
    &.slick-prev {
      left: 0;
    }
    &.slick-next {
      right: 0;
    }
  }
  &:hover .slick-arrow {
    @include breakpoint($large-up) {
      opacity: 1;
    }
  }
}

.product-full__video {
  cursor: pointer;
  &-image {
    top: 50%;
    @include transform(translateY(-50%));
  }
}

.product-full__video-image,
.product-full__carousel__thumbnail,
.product-full__carousel__thumbnail--video {
  position: relative;
  .product-full__video-play {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    position: absolute;
  }
  .icon {
    position: absolute;
  }
}

.product-full__video-image {
  .product-full__video-play {
    height: 90px;
    left: calc(50% - 45px);
    top: calc(50% - 45px);
    width: 90px;
  }
  .icon {
    height: 32px;
    left: calc(50% - 16px);
    top: calc(50% - 16px);
    width: 32px;
  }
}

.product-full__carousel__thumbnail,
.product-full__carousel__thumbnail--video {
  .product-full__video-play {
    height: 50px;
    left: calc(50% - 25px);
    top: calc(50% - 25px);
    width: 50px;
  }
  .icon {
    height: 24px;
    left: calc(50% - 12px);
    top: calc(50% - 12px);
    width: 24px;
  }
}

.product-full__carousel__thumbnail--video {
  .icon {
    font-size: 44px;
  }
}

.product-full__review-snippet {
  cursor: pointer;
}

.product-full__image-zoom {
  position: relative;
  z-index: 1;
  padding-left: 24px;
  width: 50px;
  height: 18px;
  border-bottom: 0;
  display: none;
  .icon--search {
    @include breakpoint($medium-up) {
      display: none;
    }
  }
}

.product-full__img-zoom-modal {
  position: fixed; /* Stay in place */
  z-index: 9999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: $color-white; /* Fallback color */
  img {
    width: 150%;
    max-width: none;
    position: absolute;
    height: auto;
    margin: auto;
  }
  .close {
    background: transparent;
    position: fixed;
    right: 30px;
    top: 18px;
    width: 12px;
    height: 12px;
  }
}

.center-mode-carousel {
  $y_origin: 50%;
  $x_offset: 20px;
  //$y_origin: bottom;
  $scale: 0.8;
  & > .slick-list > .slick-track {
    @include breakpoint($medium-down) {
      > .slick-slide {
        opacity: 0.5;
        -webkit-backface-visibility: hidden;
        @include transform(scale($scale) translateX(-$x_offset));
        @include transition(transform 0.4s ease, opacity 0.4s ease-in-out);
        transform-origin: right $y_origin;
        .basic-carousel__title,
        .basic-carousel__description {
          opacity: 0;
        }
        &.slick-center + div {
          transform-origin: left $y_origin;
          @include transform(scale($scale) translateX($x_offset));
        }
        &.slick-center {
          transform-origin: left $y_origin;
          @include transform(scale(1) translateX(0));
        }
      }
      // this applies to all visible
      > .slick-slide.slick-active,
      > .slick-slide.slick-center {
        opacity: 1;
        @include transform(scale(1));
        .basic-carousel__title,
        .basic-carousel__description {
          opacity: 1;
        }
      }
    }
  }
  &.sliding-left > .slick-list > .slick-track > .slick-slide.slick-center {
    transform-origin: right $y_origin;
    @include transform(scale(1) translateX(0));
  }
}
